<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          close-on-content-click
          transition="scale-transition"
          full-width
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :max="maxDate"
            :allowed-dates="allowedMonths"
            min="2018-01"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-spacer />
      </v-toolbar>
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          solo-inverted
          clearable
          box
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :pagination.sync="pagination"
        :search="search"
        class="px-1 pb-1"
        item-key="entry"
      >
        <template
          slot="items"
          slot-scope="props"
        >
          <tr @click="props.expanded = !props.expanded">
            <td class="text-xs-left">
              {{ props.item.entry }}
            </td>
            <td
              v-if="hasSubordinate"
              class="text-xs-left"
            >
              {{ props.item.user }}
            </td>
            <td class="text-xs-left">
              {{ props.item.action }}
            </td>
            <td class="text-xs-left">
              {{ props.item.target }}
            </td>
            <td class="text-xs-left">
              {{ props.item.phase }}
            </td>
            <td
              v-if="props.item.status === 'Success'"
              class="text-xs-left"
            >
              <span class="success--text">{{ props.item.status }}</span>
            </td>
            <td
              v-else-if="props.item.status === 'Error'"
              class="text-xs-left"
            >
              <span class="error--text">{{ props.item.status }}</span>
            </td>
            <td
              v-else-if="props.item.status === 'Pending'"
              class="text-xs-left"
            >
              <span class="warning--text">{{ props.item.status }}</span>
            </td>
            <td
              v-else
              class="text-xs-left"
            >
              {{ props.item.status }}
            </td>
          </tr>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template
          slot="expand"
          slot-scope="props"
        >
          <v-card class="elevation-1 mx-2 mb-2 inner">
            <v-card-text>
              <v-container
                fluid
                grid-list-xs
              >
                <v-layout
                  v-if="props.item.status === 'Error'"
                  row
                  wrap
                >
                  <v-flex xs3>
                    <span class="primary--text">Message</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.remark }}
                  </v-flex>
                  <template v-if="props.item.action === 'RegisterMNP' && props.item.reference || props.item.action === 'FamilyMnp' && props.item.reference || props.item.action === 'SiswaPlusMnp' && props.item.reference">
                    <v-flex xs3>
&nbsp;
                    </v-flex>
                    <v-flex xs9>
                      <v-btn
                        slot="activator"
                        color="primary"
                        @click="resubmitMnp(props.item)"
                      >
                        <span>Re-submit&nbsp;</span>
                        <v-icon>replay</v-icon>
                      </v-btn>
                    </v-flex>
                  </template>
                </v-layout>
                <v-layout
                  v-else-if="props.item.status === 'Success' && (props.item.action === 'COBP' || props.item.action.startsWith('Register'))"
                  row
                  wrap
                >
                  <v-flex xs3>
                    <span class="primary--text">Purchased plan</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.payload.plan }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Subscriber</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.payload.name }}
                  </v-flex>
                </v-layout>
                <v-layout
                  v-else-if="props.item.status === 'Success' && props.item.action === 'Transfer Black pin'"
                  row
                  wrap
                >
                  <v-flex xs3>
                    <span class="primary--text">Dealer</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.payload.name }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Quantity</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.payload.quantity }}
                  </v-flex>
                </v-layout>
                <v-layout
                  v-else-if="props.item.status === 'Success' && props.item.action === 'Pay bill'"
                  row
                  wrap
                >
                  <v-flex xs3>
                    <span class="primary--text">Outstanding</span>
                  </v-flex>
                  <v-flex xs9>
                    RM {{ props.item.payload.outstanding }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Payment</span>
                  </v-flex>
                  <v-flex xs9>
                    RM {{ props.item.payload.payment }}
                  </v-flex>
                </v-layout>
                <v-layout
                  v-else-if="props.item.status === 'Success' && props.item.action === 'Appoint dealer'"
                  row
                  wrap
                >
                  <v-flex xs3>
                    <span class="primary--text">Dealer Id</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.payload.dealer }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Name</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.payload.name }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Appointed as</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.payload.appointmentAs }}
                  </v-flex>
                </v-layout>
                <v-layout
                  v-else-if="props.item.status === 'Success' && (props.item.action === 'Transfer Dealer pin' || props.item.action === 'Transfer SD pin')"
                  row
                  wrap
                >
                  <v-flex xs3>
                    <span class="primary--text">Quantity</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.payload.quantity }}
                  </v-flex>
                </v-layout>
                <v-layout
                  v-else-if="props.item.status === 'Success' && props.item.action === 'Transfer e-recharge'"
                  row
                  wrap
                >
                  <v-flex xs3>
                    <span class="primary--text">Amount</span>
                  </v-flex>
                  <v-flex xs9>
                    RM {{ props.item.payload.amount }}
                  </v-flex>
                </v-layout>
                <v-layout
                  v-else-if="props.item.status === 'Success' && props.item.action === 'Topup'"
                  row
                  wrap
                >
                  <v-flex xs3>
                    <span class="primary--text">Amount</span>
                  </v-flex>
                  <v-flex xs9>
                    RM {{ props.item.payload.amount ? props.item.payload.amount : '-' }}
                  </v-flex>
                </v-layout>
                <v-layout
                  v-else-if="props.item.remark"
                  row
                  wrap
                >
                  <v-flex xs3>
                    <span class="primary--text">Remark</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.remark }}
                  </v-flex>
                </v-layout>
                <v-layout
                  v-else
                  row
                  wrap
                >
                  <v-flex xs12>
                    There's nothing to show.
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </template>
      </v-data-table>
    </v-card>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
import security from '@/security'

const reportTitle = 'Activities'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
      ],
      expand: true,
      items: [
      ],
      loading: false,
      maxDate: '',
      menu: false,
      monthOf: null,
      noDataText: "There's nothing to display.",
      noResultText: 'No data that fits that criteria.',
      pagination: {
        descending: true,
        sortBy: 'entry',
      },
      search: '',
      title: reportTitle,
    }
  },
  computed: {
    headers () {
      const headers = [
        {
          align: 'left',
          sortable: true,
          text: 'Entry Date',
          value: 'entry',
        },
      ]

      this.hasSubordinate && headers.push(
        {
          align: 'left',
          sortable: true,
          text: 'User',
          value: 'user',
        },
      )

      headers.push(
        {
          align: 'left',
          sortable: true,
          text: 'Action',
          value: 'action',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Target',
          value: 'target',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Detail',
          value: 'phase',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Status',
          value: 'status',
        },
      )

      return headers
    },
    accountType () {
      const properties = security.me.getProperties()
      return properties.find(prop =>
        prop.name === 'account.type',
      )
    },
    hasSubordinate () {
      if (!this.accountType) {
        return false
      }

      const username = security.me.username()

      if (this.accountType.value === 'dominant') {
        return username === security.me.msisdn() || username === security.me.dealerId()
      }

      return this.accountType.value.indexOf('@') > -1 && username.toUpperCase() === this.accountType.value.toUpperCase()
    },
  },
  watch: {
    monthOf: function (val) {
      this.getActivities(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2018, 1, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getActivities (month) {
      this.loading = true
      const params = createGetParams({
        month: month,
      })
      this.loading = true
      this.$rest.get('getActivities.php', params)
        .then(function (response) {
          this.items = response.data
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
    resubmitMnp: function (item) {
    //   let path = item.reference.startsWith('ONEX') || item.reference.startsWith('XONEX')
    //     ? '/prepaid_resubmit_mnp/' : '/black_register_subscriber/'

      //   path += item.reference
      //   this.$router.push({
      //     path,
      //   })
      // },

      if (item.reference.startsWith('ONEX') || item.reference.startsWith('XONEX')) {
        let path = '/prepaid_resubmit_mnp/'
        path += item.reference
        this.$router.push({
          path,
        })
      } else if (item.reference.endsWith('M')) {
        let path = '/mysiswa_register_subscriber/'
        path += item.reference
        this.$router.push({
          path,
        })
      } else if (item.reference.endsWith('F')) {
        let path = '/family_register_subscriber/'
        path += item.reference
        this.$router.push({
          path,
        })
      } else {
        let path = '/black_register_subscriber/'
        path += item.reference
        this.$router.push({
          path,
        })
      }
    },
  },
}
</script>
